html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
}

#root {
	margin: 0;
	padding: 0;
	height: 100%;
	/* min-height: 100%;
	height: auto !important;
	height: 100%; */
	/* overflow: hidden !important; */
}

#search-bar-0.form-control {
	margin-bottom: 5px;
}

#cetak-surat > table {
	page-break-inside: auto;
}

#cetak-surat > table > tr {
	page-break-inside: avoid;
	page-break-after: auto;
}

#cetak-surat > table > tr > thead {
	display: table-header-group;
}

#cetak-surat > table > tr > tfoot {
	display: table-header-group;
}

input:focus {
	/* background-color: yellow !important; */
	border-color: #00ff66 !important;
}

.kiosk-card {
	box-shadow: 0 4px 8px 0 #0000003d, 0 6px 20px 0 #0000003d;
	background-color: #ffffffc7;
	cursor: pointer;
}

.kiosk-card:hover {
	box-shadow: 0 4px 8px 0 #0000009e, 0 6px 20px 0 #0000009e;
}

.logout-kiosk:hover {
	box-shadow: 0 4px 8px 0 #ffffff, 0 6px 20px 0 #ffffff;
	background-color: brown;
}

#bg-kiosk {
	width: 100%;
	content: '';
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	opacity: 1;
	background-image: url('../images/kiosk/cv.png');
	background-position: center;
	background-size: cover;
	/* z-index: -1; */
	/* position: absolute; */
	min-height: 100%;
	height: auto !important;
	height: 100%;
	overflow: hidden !important;
}

#bg-beranda-kiosk {
	width: 100%;
	content: '';
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	opacity: 1;
	background-image: url('../images/kiosk/cv.png');
	background-position: center;
	background-size: cover;
	/* width: 100%; */
	/* height: 100vh; */
	/* height: calc(100vw * (9/16)); */
	min-height: 100%;
	height: auto !important;
	height: 100%;
	overflow: hidden !important;
}

#bg-login-kiosk{
	background-image: url('../images/kiosk/cv.png');
	background-position: center;
	background-size: cover;
}

#bg-login-digidesa{
	background-image: url('../images/kiosk/cv.png');
	background-position: center;
	background-size: cover;
}

a:hover {
	text-decoration: none !important;
}

a:active {
	text-decoration: none !important;
}

a:focus {
	text-decoration: none !important;
}

input {
	color: black !important;
}

p {
	margin-bottom: 1rem !important;
}

.table-custom{
	border:1px solid black;
	/* margin-top:20px; */
}
.table-custom > thead > tr > th{
	border:1px solid black;
	text-align: center;
	padding: 0.35rem !important;
}
.table-custom > tbody > tr > td{
	border:1px solid black;
	text-align: center;
	padding: 0.35rem !important;
}

.mb-15 {
	margin-bottom: 15px !important;
}

@media print {
	body * {
		visibility: hidden;
	}
	#kertas_antrian,
	#kertas_antrian * {
		visibility: visible;
		/* width: 80mm; */
		/* height: 80mm; */
	}
	#kertas_antrian {
		position: absolute;
		left: 0;
		top: 0;
	}
}
